import React, { useEffect } from 'react';
import styled from 'styled-components';
import qrious from 'qrious';
import { ButtonGroup, Heading, Icon } from '@sine/sine-ui-components';


import { VACCINE_DOSE } from '../constants/vaccines';
import { TIMER } from './pages';
import { ANSWER } from '../constants/answer';
import { CardContent, UserDetailsQR, MessageAlert, ButtonWhite, LaneDetails } from '../components';
import storage from '../helpers/storage';
import clockIcon from '../assets/images/clock.svg';

const MainTitle = styled(Heading)`
  color: #ffffff !important;
  font-weight: bold;
  font-size: 23px;
  line-height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const QRContainer = styled.div`
  text-align: center;
`;

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 204px;
  padding: 8px;
  background: #fff;
  border-radius: 4px;
  margin: 0 auto;
`;

const StyledAlert = styled(MessageAlert)`
  border-left-width: 1px;
`;

const TimerButton = styled(ButtonWhite)`
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 28px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      > img {
        margin-right: 8px;
      }
    }
  }
`;

function getQriousWidth() {
  let width = window.innerWidth / 1.5;
  return width >= 204 ? 204 : width;
}

function getQRValue({ csn, fin }) {
  if (csn && fin) {
    return `${csn}, ${fin}`;
  } else {
    return csn || fin;
  }
}

export default function QRPage({ state, onNext }) {
  useEffect(() => {
    if (state.csn || state.fin) {
      new qrious({
        element: document.getElementById('qr'),
        value: getQRValue(state),
        size: getQriousWidth(),
      });
    }

    if (state.question3 === ANSWER.YES) {
      document.getElementsByClassName('card-color')[0].style.background = '#F68325';
      document.getElementsByClassName('card-color')[0].style.border = '1px solid #F68325';
    } else {
      document.getElementsByClassName('card-color')[0].style.background = '#00B57E';
      document.getElementsByClassName('card-color')[0].style.border = '1px solid #00B57E';
    }

    !state.completedAt && storage.saveForm(state.fin,state.csn,state);
  }, [state]);

  const handleClick = () => {
    storage.saveShotTime(state.fin,state.csn,Date.now());
    onNext(undefined, TIMER.id);
  };

  return (
    <CardContent>
      <MainTitle type='h1' alignment='center'>
        Show this on arrival.
      </MainTitle>
      {state.csn || state.fin ? (
        <QRContainer>
          <LaneDetails state={state} />
          <QRWrapper>
            <canvas id='qr'></canvas>
          </QRWrapper>
        </QRContainer>
      ) : (
        <QRContainer>
          <LaneDetails state={state} />
        </QRContainer>
      )}
      <ButtonGroup stacked alignment='center'>
        <TimerButton onClick={handleClick}>
          <div>
            <img src={clockIcon} alt='Start my timer' />
            Start My Timer
          </div>
          <Icon type='chevron-right' size='sm' color='#223E67' />
        </TimerButton>
      </ButtonGroup>

      <UserDetailsQR color='#FFFFFF' value={`${state.firstName} ${state.lastName}`} />
      {/* <UserDetailsQR color='#FFFFFF' title='Email' value={state.email} />
      <UserDetailsQR color='#FFFFFF' title='Mobile' value={formatPhoneNumber(state.mobile)} />
      <UserDetailsQR color='#FFFFFF' title='CSN Number' value={state.csn} /> */}

      {state.dob && (
        <UserDetailsQR
          color='#FFFFFF'
          title='DOB'
          value={state.dob ? new Date(state.dob).toLocaleString('en-US').split(',')[0] : null}
        />
      )}
      <UserDetailsQR color='#FFFFFF' title='FIN Number' value={state.fin} />

      {state.question3 === ANSWER.YES && (
        <StyledAlert warn>
          For monitoring purposes, please remain on site for 30 minutes after receiving your vaccination.
        </StyledAlert>
      )}
    </CardContent>
  );
}
