import React from 'react';
import styled from 'styled-components';
import { Button } from '@sine/sine-ui-components';

export const ButtonSolid = styled((props) => <Button solid warn {...props} />)`
  height: 44px;
  text-transform: unset;
  background-color: #f0c300;
  color: #373e41;
`;

export const ButtonOutlined = styled((props) => <Button outlined {...props} />)`
  font-weight: 600;
  height: 44px;
  text-transform: unset;
  border-color: #00818a;
  color: #373e41;

  &:hover:not([disabled]) {
    color: #00818a;
  }
`;

export const ButtonWhite = styled((props) => <Button solid white size='lg' {...props} />)`
  position: relative;
  margin: 18px 0;
  text-transform: unset;
  color: #223E67;
  font-size: 16px;
`;
