import React from 'react';
import styled from 'styled-components';

const ListTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0.2em !important;
  ${({ color }) => `color: ${color} !important;`}
`;

const ListAnswer = styled.p`
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  margin-top: 0.2em !important;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ color }) => `color: ${color} !important;`}
`;

export function UserDetails({ title, value, color }) {
  return value ? (
    <>
      <ListTitle color={color}>{title}</ListTitle>
      <ListAnswer color={color}>{value}</ListAnswer>
    </>
  ) : null;
}
