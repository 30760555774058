import React from 'react';
import styled from 'styled-components';
import { Heading } from '@sine/sine-ui-components';

export const Title = styled((props) => <Heading type='h1' alignment='center' {...props} />)`
  font-size: 25px;
  font-weight: 700;
  line-height: 31.35px;
  color: #223e67;
`;
