import React from 'react';
import { Text } from '@sine/sine-ui-components';

import PersonalDetails from './PersonalDetails';
import Question from './Question';
import QRPage from './QRPage';
import BookAppointment from './BookAppointment';
import Message from './Message';
import VaccineRadioButton from './VaccineRadioButton';
import Timer from './Timer';
import { MessageAlert } from '../components/Alert';
import { CardContentInner } from '../components/Card';
import { Paragraph } from '../components/Paragraph';
import { MyAtriumHealthLink } from '../components/MyAtriumHealthLink';
import { ANSWER } from '../constants/answer';
import { VACCINE_DOSE } from '../constants/vaccines';

export const PERSONAL_DETAILS = {
  id: 'personalDetails',
  title: <Text>Personal Details</Text>,
  subtitle: <Text>Please complete the questions below.</Text>,
  question: '',
  Page: ({ onNext, setState, setGoToId }) => (
    <PersonalDetails onNext={onNext} setState={setState} setGoToId={setGoToId} />
  ),
};

export const ERROR_1 = {
  id: 'error1',
  Page: ({ onNext, onYes, onNo, state }) => (
    <BookAppointment
      text={
        <Paragraph>
          In order that you receive the correct vaccination, please reschedule your appointment in{' '}
          <MyAtriumHealthLink />.
        </Paragraph>
      }
    />
  ),
};

export const ERROR_3 = {
  id: 'error3',
  Page: ({ onNext, onYes, onNo, state }) => (
    <BookAppointment
      text={
        <>
          <Paragraph>COVID-19 vaccination is NOT recommended for you at this time.</Paragraph>
          <Paragraph>Other vaccinations may cause a low immune response to your COVID-19 vaccine.</Paragraph>
          <Paragraph>
            Please reschedule your COVID-19 vaccination in <MyAtriumHealthLink />.
          </Paragraph>
        </>
      }
    />
  ),
};

export const ERROR_4 = {
  id: 'error4',
  Page: ({ onNext, onYes, onNo, state }) => (
    <BookAppointment
      text={
        <>
          <Paragraph>
            To minimize the risk to others, COVID-19 vaccination is not recommended for you at this time.
          </Paragraph>
          <Paragraph>
            Please reschedule your COVID-19 vaccination via <MyAtriumHealthLink />.
          </Paragraph>
        </>
      }
    />
  ),
};

export const ERROR_5 = {
  id: 'error5',
  Page: ({ onNext, onYes, onNo, state }) => (
    <BookAppointment
      text={
        <>
          <Paragraph>COVID-19 vaccination is NOT recommended for you at this time.</Paragraph>
          <Paragraph>You should NOT receive a COVID-19 vaccine within 90 days of receiving those therapies.</Paragraph>
          <Paragraph>
            Please reschedule your COVID-19 vaccination <MyAtriumHealthLink />.
          </Paragraph>
        </>
      }
    />
  ),
};

export const ERROR_6 = {
  id: 'error6',
  Page: ({ onNext, onYes, onNo, state }) => (
    <BookAppointment
      text={
        <Paragraph>
          When you have all of your questions answered by your OB doctor, please complete this form again or reschedule
          your vaccine in <MyAtriumHealthLink />.
        </Paragraph>
      }
    />
  ),
};

export const TIMER = {
  id: 'timer',
  Page: ({ onNext, onYes, onNo, state }) => (
    <Timer state={state} onNext={onNext} waitTime={state.question3 === ANSWER.YES ? 30 : 15} />
  ),
};

export const QR_CODE = {
  id: 'qr_code',
  Page: ({ onNext, onYes, onNo, state }) => <QRPage state={state} onNext={onNext} />,
};


export const QUESTION_11 = {
  id: 'question11',
  yesGoTo: QR_CODE.id,
  noGoTo: ERROR_6.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <>
          <MessageAlert warn>
          In some cases, a COVID-19 vaccination may cause swelling at the site of previous dermal fillers. Please be aware of this potential side effect and discuss this with your doctor if you have any concerns.
          </MessageAlert>
          <CardContentInner>
            <Paragraph>Are you comfortable continuing with the vaccination?</Paragraph>
          </CardContentInner>
        </>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_11.id]}
    />
  ),
};

export const QUESTION_10 = {
  id: 'question10',
  yesGoTo: QUESTION_11.id,
  noGoTo: QR_CODE.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Do you have dermal fillers?</Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_10.id]}
    />
  ),
};

export const QUESTION_9 = {
  id: 'question9',
  yesGoTo: QUESTION_10.id,
  noGoTo: ERROR_6.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <>
          <MessageAlert warn>
            We recommend that you talk to your OB Doctor prior to receiving COVID-19 vaccine.
          </MessageAlert>
          <CardContentInner>
            <Paragraph>Are you comfortable continuing with the vaccination?</Paragraph>
          </CardContentInner>
        </>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_9.id]}
    />
  ),
};

export const QUESTION_8 = {
  id: 'question8',
  yesGoTo: QUESTION_9.id,
  noGoTo: QUESTION_10.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Are you pregnant or breastfeeding?</Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_8.id]}
    />
  ),
};

export const ERROR_7 = {
  id: 'error7',
  nextGoTo: QUESTION_8.id,
  Page: ({ onNext }) => (
    <Message
      title='COVID Vaccine Prescreen'
      subtitle='Please note the following'
      text={
        <>
          You may be at increased risk of bleeding after your injection.
          <br />
          <br />
          Please let one of our vaccine nurses know this so that we can manage any potential bleeding.
        </>
      }
      onNext={onNext}
    />
  ),
};

export const QUESTION_7 = {
  id: 'question7',
  yesGoTo: ERROR_7.id,
  noGoTo: QUESTION_8.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Do you have a bleeding disorder and/or are you taking a blood thinner?</Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_7.id]}
    />
  ),
};

export const QUESTION_6 = {
  id: 'question6',
  yesGoTo: ERROR_5.id,
  noGoTo: QUESTION_7.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>
            If you have had COVID-19, did you receive passive antibody therapy (monoclonal antibodies or convalescent
            serum) as a treatment for COVID-19 within the last 90 days?
          </Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_6.id]}
    />
  ),
};

export const QUESTION_5 = {
  id: 'question5',
  yesGoTo: ERROR_4.id,
  noGoTo: QUESTION_6.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>
            In the last 14 days:
            <br />
            Has a doctor told you that you have COVID-19 or have you had a positive test result for COVID-19?
          </Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_5.id]}
    />
  ),
};

export const QUESTION_4 = {
  id: 'question4',
  yesGoTo: ERROR_3.id,
  noGoTo: QUESTION_5.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Have you received another vaccine in the last 14 days?</Paragraph>
          <MessageAlert info>
            This includes a previous COVID-19 vaccination as well as other routine vaccinations.
          </MessageAlert>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_4.id]}
    />
  ),
};

export const ERROR_2 = {
  id: 'error2',
  nextGoTo: QUESTION_5.id,
  Page: ({ onNext }) => (
    <Message
      title='COVID Vaccine Prescreen'
      subtitle='Please note the following'
      text={
        <>
          <Paragraph disableStyles>
            For your safety, you will be asked to stay for 30 minutes after receiving the vaccine for monitoring.
          </Paragraph>
          <Paragraph disableStyles>Medical staff will be on site in case of emergency</Paragraph>
        </>
      }
      onNext={onNext}
    />
  ),
};

export const QUESTION_3 = {
  id: 'question3',
  yesGoTo: ERROR_2.id,
  noGoTo: QUESTION_5.id,
  Page: ({ onNext, onYes, onNo, state }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Have you ever had an allergic reaction to:</Paragraph>
          <Paragraph>
            <ol type='a'>
              <li>
                <b>Any vaccine</b>
                <br />
                Including a prior dose of COVID-19 vaccine;
              </li>
              <li>
                <b>Polysorbate</b>
                <br />
                An oily liquid used in some medications or foods;
              </li>
              <li>
                <b>Polyethylene glycol (PEG)</b>
                <br />
                Found in some medications, such as laxatives and preparations for colonoscopy;
              </li>
              <li>
                <b>To something other than vaccines?</b>
                <br />
                This would include a severe reaction to food, pet, environmental, or oral medication.
              </li>
            </ol>
          </Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      onYes={onYes}
      onNo={onNo}
      value={state[QUESTION_3.id]}
    />
  ),
};

export const ERROR_8 = {
  id: 'error8',
  nextGoTo: QUESTION_3.id,
  Page: ({ onNext }) => (
    <Message
      title='COVID Vaccine Prescreen'
      subtitle='Please note the following'
      text='Please check your vaccine card and be sure that you are scheduled to get the same vaccine as your first dose. You will also need to tell the vaccine team which vaccine you had for your first dose.'
      onNext={onNext}
    />
  ),
};

export const QUESTION_2 = {
  id: 'question2',
  Page: ({ onNext, state, setState }) => (
    <VaccineRadioButton
      text={
        <CardContentInner>
          <Paragraph>Which type of vaccine did you previously receive?</Paragraph>
        </CardContentInner>
      }
      onNext={onNext}
      value={state[QUESTION_2.id]}
      setState={setState}
    />
  ),
};

export const QUESTION_1 = {
  id: 'question1',
  yesGoTo: QUESTION_3.id,
  noGoTo: QUESTION_3.id,
  Page: ({ onNext, onYes, onNo, state, setState }) => (
    <Question
      text={
        <CardContentInner>
          <Paragraph>Have you already received a dose of the COVID-19 vaccine?</Paragraph>
        </CardContentInner>
      }
      onNext={() => onNext(undefined, state.mfg ? QUESTION_3.id : undefined)}
      onYes={() => {
        setState((prevState) => ({ ...prevState, dose: VACCINE_DOSE.SECOND }));
        onYes();
      }}
      onNo={() => {
        setState((prevState) => ({ ...prevState, dose: VACCINE_DOSE.FIRST }));
        onNo();
      }}
      value={state[QUESTION_1.id]}
    />
  ),
};

export const pages = {
  [PERSONAL_DETAILS.id]: PERSONAL_DETAILS,
  [QUESTION_1.id]: QUESTION_1,
  [QUESTION_2.id]: QUESTION_2,
  [QUESTION_3.id]: QUESTION_3,
  [QUESTION_4.id]: QUESTION_4,
  [QUESTION_5.id]: QUESTION_5,
  [QUESTION_6.id]: QUESTION_6,
  [QUESTION_7.id]: QUESTION_7,
  [QUESTION_8.id]: QUESTION_8,
  [QUESTION_9.id]: QUESTION_9,
  [QUESTION_10.id] : QUESTION_10,
  [QUESTION_11.id] : QUESTION_11,
  [ERROR_1.id]: ERROR_1,
  [ERROR_2.id]: ERROR_2,
  [ERROR_3.id]: ERROR_3,
  [ERROR_4.id]: ERROR_4,
  [ERROR_5.id]: ERROR_5,
  [ERROR_6.id]: ERROR_6,
  [ERROR_7.id]: ERROR_7,
  [ERROR_8.id]: ERROR_8,
  [QR_CODE.id]: QR_CODE,
  [TIMER.id]: TIMER,
};
