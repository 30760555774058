import React from 'react';
import { ButtonGroup } from '@sine/sine-ui-components';

import { ButtonOutlined, ButtonSolid, CardContent, CardFooter, SubTitle, Title } from '../components';
import { ANSWER } from '../constants/answer';

const StyledButton = ({ isSelected, title, onClick }) =>
  isSelected ? (
    <ButtonSolid onClick={onClick}>{title}</ButtonSolid>
  ) : (
    <ButtonOutlined onClick={onClick}>{title}</ButtonOutlined>
  );

export default function Question({ text, onNext, onYes, onNo, value }) {
  return (
    <>
      <CardContent>
        <Title>COVID Vaccine Prescreen</Title>
        <SubTitle>Please answer the questions below</SubTitle>
        {text}
        <ButtonGroup start>
          <StyledButton isSelected={value === ANSWER.YES} onClick={onYes} title='Yes' />
          <StyledButton isSelected={value === ANSWER.NO} onClick={onNo} title='No' />
        </ButtonGroup>
      </CardContent>

      <CardFooter>
        <ButtonGroup end>
          <ButtonSolid onClick={onNext} disabled={!value}>
            Next
          </ButtonSolid>
        </ButtonGroup>
      </CardFooter>
    </>
  );
}
