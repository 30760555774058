import 'fast-text-encoding';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import MainContainer from './components/MainContainer';

ReactDOM.render(<MainContainer />, document.getElementById('root'));
