export const VACCINE_NAME = {
  PFIZER: 'Pfizer',
  MODERNA: 'Moderna',
  UNKNOWN: 'Unknown',
};

export const VACCINE_DOSE = {
  FIRST: 'First',
  SECOND: 'Second',
};
