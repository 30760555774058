import styled from 'styled-components';

export const Paragraph = styled.span`
  display: block;

  & + & {
    margin-top: 16px;
  }

  ${({ disableStyles }) => {
    return !disableStyles
      ? `
        font-size: 16px;
        line-height: 24px;
        color: #223e67;
      `
      : '';
  }}
`;
