import React from 'react';
import styled from 'styled-components';

import { ANSWER } from '../constants/answer';
import { VACCINE_DOSE } from '../constants/vaccines';

const LaneDetailsWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #223e67;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: max-content;
  padding: 0 24px;
  margin: 24px auto;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
`;

const StyledVerticalBar = styled.div`
  font-size: 18px;
  opacity: 25%;
  margin: 0px 8px;
`;

export function LaneDetails({ state: { question3, mfg, dose } }) {
  const lanePhrase = question3 === ANSWER.YES ? 'ORANGE' : 'GREEN';
  const manufacturerPhrase = mfg ? `${mfg.toUpperCase()}` : '';
  const dosePhrase = dose === VACCINE_DOSE.FIRST ? 'DOSE 1' : dose === VACCINE_DOSE.SECOND ? 'DOSE 2' : '';

  return (
    <LaneDetailsWrapper>
      {lanePhrase}
      {(manufacturerPhrase || dosePhrase) && <StyledVerticalBar>|</StyledVerticalBar>}
      {/* {manufacturerPhrase} */}
      {/* {manufacturerPhrase && dosePhrase && ', '} */}
      {dosePhrase}
    </LaneDetailsWrapper>
  );
}
