import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  fontweight: bold;
  color: #428BCA;
  text-decoration: none;
`;

export function MyAtriumHealthLink() {
  return <Link href='https://my.atriumhealth.org/MyAtriumHealth/Authentication/Login'>MyAtriumHealth</Link>;
}
