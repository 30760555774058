import React from 'react';
import styled from 'styled-components';

const ListDetail = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 0.2em !important;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ color }) => `color: ${color} !important;`}
`;

export function UserDetailsQR({ title, value, color }) {
  return value ? (
    <>
      <ListDetail color={color}>
        {title ? `${title}: ` : ''}
        {value}
      </ListDetail>
    </>
  ) : null;
}
