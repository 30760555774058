import { fromPairs } from 'lodash';

export default function getQueryParams(url) {
  const queryString = url ? url.split('?')[1] : '';
  if (queryString) {
    return fromPairs(
      queryString.split('&').map((string) => {
        const [key, value] = string.split('=');
        return [key, decodeURIComponent(value)];
      }),
    );
  }
  return {};
}
