import { VACCINE_DOSE , VACCINE_NAME } from '../constants/vaccines';


const STORAGE_KEY = {
  FORM: 'form-responses',
  TIMER: 'time-started-at'
}

function lookupKeyForm(fin,csn) {
  if(fin !== undefined && csn !== undefined && fin.localeCompare('') !==0 && csn.localeCompare('') !==0) return fin + "-" + csn + "-" +STORAGE_KEY.FORM; 
  else return STORAGE_KEY.FORM;
} 
function lookupKeyTimer(fin,csn) {
  if(fin !== undefined && csn !== undefined && fin.localeCompare('') !==0 && csn.localeCompare('') !==0 ) return fin + "-" + csn + "-" +STORAGE_KEY.TIMER;
  else return STORAGE_KEY.TIMER;
} 

function doseName(param) {
  switch (param) {
    case '1':
      return VACCINE_DOSE.FIRST;
    case '2':
      return VACCINE_DOSE.SECOND;
    default:
      return '';
  }
}

function vaccineName(vaccine) {
  switch (vaccine) {
    case 'p':
    case 'P':
      return VACCINE_NAME.PFIZER;
    case 'm':
    case 'M':
      return VACCINE_NAME.MODERNA;
    case 'u':
    case 'U':
      return VACCINE_NAME.UNKNOWN;
    default:
      return '';
  }
}

const storage = {

  loadForm: function (params) {
    try {
      const storedItem =  JSON.parse(localStorage.getItem(lookupKeyForm(params.fin,params.csn))) || {};
      if( params.csn.localeCompare( storedItem.csn) === 0 &&
          params.fin.localeCompare(storedItem.fin) === 0 &&
           params.fn.localeCompare(storedItem.firstName) === 0 && 
           params.ln.localeCompare(storedItem.lastName) ===  0 && 
          //  vaccineName(params.mfg).localeCompare(storedItem.mfg) ===  0 && 
           doseName(params.d).localeCompare(storedItem.dose) === 0
          )
        {
          return storedItem ;
        }
        
      return  {};

    } catch (e) {
      

      return {}; 
    }
  },
  saveForm: function (fin,csn,responses = {}) {
    localStorage.setItem(
      lookupKeyForm(fin,csn),
      JSON.stringify({
        ...responses,
        completedAt: Date.now(),
      }),
    );
  },
  clearForm: function (fin,csn) {
    localStorage.removeItem(lookupKeyForm(fin,csn));
  },
  loadShotTime: function (fin,csn) {
    try {
      return localStorage.getItem(lookupKeyTimer(fin,csn)) || null;
    } catch (e) {
      return {};
    }
  },
  saveShotTime: function (fin,csn,shotTime) {
    localStorage.setItem(lookupKeyTimer(fin,csn), shotTime);
  },
  clearShotTime: function (fin,csn) {
    localStorage.removeItem(lookupKeyTimer(fin,csn));
  },
};

export default storage;
