import React, { useEffect } from 'react';
import { ButtonGroup, Input, Form, DatePicker, FormHelpers } from '@sine/sine-ui-components';
import styled from 'styled-components';

import { ButtonSolid, CardContent, CardFooter, SubTitle, Title, UserDetails } from '../components';
import storage from '../helpers/storage';
import getQueryParams from '../helpers/getQueryParams';
import useJoiForm from '../hooks/useJoiForm';
import { personalDetailsSchema } from '../schemas/personalDetails';
import { VACCINE_NAME, VACCINE_DOSE } from '../constants/vaccines';
import { FORM_LIFETIME } from '../constants/time';
import { QR_CODE, QUESTION_1, QUESTION_2, QUESTION_3, TIMER } from './pages';

const StyledCardContent = styled(CardContent)`
  label {
    color: #223e67;
  }

  input {
    font-size: 14px !important;
  }
`;

function processPrefillData() {
  const params = getQueryParams(window.location.href);

  function updateVaccineName(param) {
    switch (param) {
      case 'p':
      case 'P':
        return VACCINE_NAME.PFIZER;
      case 'm':
      case 'M':
        return VACCINE_NAME.MODERNA;
      case 'u':
      case 'U':
        return VACCINE_NAME.UNKNOWN;
      default:
        return '';
    }
  }

  function updateDoseName(param) {
    switch (param) {
      case '1':
        return VACCINE_DOSE.FIRST;
      case '2':
        return VACCINE_DOSE.SECOND;
      default:
        return '';
    }
  }

  const result = personalDetailsSchema.validate(
    {
      firstName: params.fn || '',
      lastName: params.ln || '',
      csn: params.csn || '',
      fin: params.fin || '',
      mfg: updateVaccineName(params.mfg) || '',
      dose: updateDoseName(params.d) || '',
    },
    {
      abortEarly: false,
    },
  );

  const errors = FormHelpers.joiResultToErrorObject(result);

  return {
    isPrefilled:
      (params.fn && !errors.firstName) ||
      (params.ln && !errors.lastName) ||
      (params.csn && !errors.csn) ||
      (params.fin && !errors.fin) ||
      (params.mfg && !errors.mfg) ||
      (params.d && !errors.d),
    readOnly: {
      firstName: !errors.firstName,
      lastName: !errors.lastName,
      dob: !errors.dob,
      mfg: !errors.mfg,
      dose: !errors.d,
    },
    initalFormValues: {
      firstName: !errors.firstName ? result.value.firstName : '',
      lastName: !errors.lastName ? result.value.lastName : '',
      csn: !errors.csn ? result.value.csn : '',
      fin: !errors.fin ? result.value.fin : '',
      dob: null,
      mfg: !errors.mfg ? result.value.mfg : '',
      dose: !errors.dose ? result.value.dose : '',
    },
  };
}

export default function PersonalDetails({ onNext, setState, setGoToId }) {
  useEffect(() => {
    const params = getQueryParams(window.location.href);

    const storedForm = storage.loadForm(params);
    const storedShotTime = storage.loadShotTime(params.fin,params.csn);
    const isFormExpired = !storedForm.completedAt || storedForm.completedAt + FORM_LIFETIME <= Date.now();

    if (storedShotTime > 0 && !isFormExpired) {
      setState((oldState) => ({ ...oldState, ...storedForm, ...storedShotTime }));
      onNext(undefined, TIMER.id);
    } else if (!isFormExpired) {
      setState((oldState) => ({ ...oldState, ...storedForm, ...storedShotTime }));
      onNext(undefined, QR_CODE.id);
    } else {

      if (Object.keys(storedForm).length !== 0)
      {
        storage.clearForm(params.fin,params.csn);
        storage.clearShotTime(params.fin,params.csn);
      }

    }
  }, []);

  const { isPrefilled, readOnly, initalFormValues } = processPrefillData();
  const form = useJoiForm(personalDetailsSchema, initalFormValues);

  useEffect(() => {
    if (isPrefilled) {
      if (!form.values.dose) {
        setGoToId(QUESTION_1.id);
      } 
      // else if (!form.values.mfg && form.values.dose === VACCINE_DOSE.SECOND) {
      //   setGoToId(QUESTION_2.id);
      // } 
      else {
        setGoToId(QUESTION_3.id);
      }
    }
  }, []);

  const wrappedOnNext = () => {
    if (form.validate()) {
      setState((oldState) => ({ ...oldState, ...form.values }));
      onNext();
    }
  };

  return (
    <>
      <StyledCardContent>
        <Form values={form.values} errors={form.errors} onFieldChange={form.onChange} onFieldBlur={form.onBlur}>
          <Title>Personal Details</Title>

          <SubTitle>
            {isPrefilled ? 'Please review your details below' : 'Please fill in the information below'}
          </SubTitle>

          {readOnly.firstName ? (
            <UserDetails color='#495770' title='First name' value={form.values.firstName} />
          ) : (
            <Input name='firstName' label='First name*' />
          )}

          {readOnly.lastName ? (
            <UserDetails color='#495770' title='Last name' value={form.values.lastName} />
          ) : (
            <Input name='lastName' label='Last name*' />
          )}

          {!readOnly.dob && (
            <DatePicker name='dob' label='Date of Birth*' onChange={(date) => form.onChange('dob', date, true)} />
          )}

          {/* {readOnly.mfg && <UserDetails color='#495770' title='Vaccine Manufacturer' value={form.values.mfg} />} */}
          {readOnly.dose && <UserDetails color='#495770' title='Dose' value={form.values.dose} />}
        </Form>
      </StyledCardContent>

      <CardFooter>
        <ButtonGroup end>
          <ButtonSolid onClick={wrappedOnNext}>Next</ButtonSolid>
        </ButtonGroup>
      </CardFooter>
    </>
  );
}
