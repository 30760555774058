import React from 'react';
import styled from 'styled-components';

import { CardContent, CardContentInner, Title } from '../components';

const ContentInner = styled(CardContentInner)`
  text-align: center;
  margin: 32px 0;
`;

export default function BookAppointment({ onClick, text }) {
  return (
    <CardContent>
      <Title>We can’t process your vaccination right now</Title>

      <ContentInner>{text}</ContentInner>
    </CardContent>
  );
}
