import React from 'react';
import { ButtonGroup } from '@sine/sine-ui-components';

import { ButtonSolid, CardContent, CardFooter, MessageAlert, SubTitle, Title } from '../components';

export default function Message({ onNext, title, subtitle, text }) {
  return (
    <>
      <CardContent>
        {title && <Title>{title}</Title>}
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
        <MessageAlert warn>{text}</MessageAlert>
      </CardContent>

      <CardFooter>
        <ButtonGroup end>
          <ButtonSolid onClick={onNext}>Next</ButtonSolid>
        </ButtonGroup>
      </CardFooter>
    </>
  );
}
