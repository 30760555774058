import React, { useEffect, useState, useRef } from 'react';
import { ButtonGroup } from '@sine/sine-ui-components';
import styled from 'styled-components';

import { ButtonOutlined, CardContent, CardFooter, SubTitle, Title, MessageAlert } from '../components';
import storage from '../helpers/storage';
import { QR_CODE } from './pages';
import { ONE_MINUTE } from '../constants/time';
import getQueryParams from '../helpers/getQueryParams';

const CounterDown = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  text-align: center;
  color: #223e67;
  margin: 0;
`;

function formatTime(secondsLeft) {
  let minutes = Math.floor((secondsLeft % (ONE_MINUTE * 60)) / ONE_MINUTE);
  let seconds = Math.floor((secondsLeft % ONE_MINUTE) / 1000);

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
}

export default function Timer({ onNext, waitTime }) {
  const [formattedTime, setFormattedTime] = useState('');
  const [timeExpired, setTimeExpired] = useState(false);

  const intervalRef = useRef();

  useEffect(() => {
    document.getElementsByClassName('card-color')[0].style.background = '#FFFFFF';
    document.getElementsByClassName('card-color')[0].style.border = '1px solid #FFFFFF';

    intervalRef.current = setInterval(() => {

      const params = getQueryParams(window.location.href);

      const waitedSoFar = Date.now() - parseInt(storage.loadShotTime(params.fin,params.csn));
      const timeout = waitTime * ONE_MINUTE;
      const remainingTime = timeout - waitedSoFar;

      if (remainingTime >= 0) {
        setFormattedTime(formatTime(remainingTime));
      } else {
        clearInterval(intervalRef.current);
        setTimeExpired(true);
        setFormattedTime(formatTime(0));
      }
    }, 1000);
    
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <>
      <CardContent>
        <Title>Countdown</Title>
        <CounterDown>{formattedTime}</CounterDown>
        {!timeExpired ? (
          <>
            <SubTitle>Once your time is finished, you may leave.</SubTitle>
            <MessageAlert info>
              Please stay on site for {waitTime} minutes. We will watch to make sure you don’t have a reaction. Please
              let our vaccination staff know if you are not feeling well so we can help.
            </MessageAlert>
          </>
        ) : (
          <>
            <SubTitle>Your {waitTime} minutes is up!</SubTitle>
            <MessageAlert info>
              If this was your first vaccine dose, please remember to return for your second dose. You can see details
              in your MyAtriumHealth account.
              <br />
              If this was your second dose, <b>congratulations!</b> You have received all of your COVID-19 vaccinations.{' '}
              <br />
              Please keep using COVID-Safe behaviors. These include
              <ol type='1'>
                <li>Wearing masks</li>
                <li>Socially distancing</li>
                <li>Washing your hands</li>
              </ol>
              Keep yourself and others safe!
            </MessageAlert>
          </>
        )}
      </CardContent>

      <CardFooter>
        <ButtonGroup stacked>
          <ButtonOutlined
            onClick={() => {
              const params = getQueryParams(window.location.href);

              storage.clearShotTime(params.fin,params.csn);
              onNext(undefined, QR_CODE.id);
            }}
          >
            Back to QR code
          </ButtonOutlined>
        </ButtonGroup>
      </CardFooter>
    </>
  );
}

