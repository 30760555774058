import styled from 'styled-components';
import { Card as UICCard } from '@sine/sine-ui-components';

export const Card = styled(UICCard)`
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border: none;
`;

export const CardContent = styled(UICCard.Content)`
  min-height: 350px;
`;

export const CardFooter = styled(UICCard.Footer)`
  border: none;
`;

export const CardContentInner = styled.div`
  margin: 16px 0;
`;
