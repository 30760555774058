import { useState, useRef } from 'react';
import { FormHelpers } from '@sine/sine-ui-components';
import { get as _get, set as _set } from 'lodash';

const DEFAULT_DEBOUNCE_WAIT = 200;

function validateForm(schema, values, fieldsToInclude, validationOptions = {}) {
  const result = schema.validate(values, { abortEarly: false, ...validationOptions });

  if (!result.error) {
    return {
      errors: {},
      hasError: false,
    };
  }

  const filteredJoiResult = !fieldsToInclude ? result : FormHelpers.filterJoiError(result, { fieldsToInclude });
  const errors = FormHelpers.joiResultToErrorObject(filteredJoiResult);
  const hasError = FormHelpers.containsError(errors);

  return {
    errors,
    hasError,
  };
}

export default function useJoiForm(schema, initialValues, validationOptions = {}) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const _validate = (formValues, fieldsToInclude) =>
    validateForm(schema, formValues, fieldsToInclude, validationOptions);

  /**
   * Sets the form field's error.
   * @param {string} name The name of the form field.
   * @param {any} error The associated error.
   */
  const onError = (name, error) => {
    setErrors((prevErrors) => _set({ ...prevErrors }, name, error));
  };

  /**
   * The form field change event.
   * @param {string} name The name of the form field.
   * @param {any} value The updated form field value.
   * @param {boolean} validate Whether to run validation on the change. Default: `true`.
   */
  const onChange = (name, value, validate = false) => {
    // For anyone reading this code later:
    // It's generally ill-advised to put a setState inside another setState, unless you know
    // exactly what the data flow looks like. Only adopt this pattern if there is no other choice.
    setValues((prevValues) => {
      const newValues = _set({ ...prevValues }, name, value);

      if (validate) {
        const result = _validate(newValues, _set({}, name, validate));
        onError(name, _get(result.errors, name));
        setIsValid(!result.hasError);
      }

      return newValues;
    });
  };

  /**
   * Updates and overrides all form values.
   * @param {any} value The updated form values.
   * @param {boolean} validate Whether to run validation on the change. Default: `true`.
   */
  const onChangeAll = (value, validate = true) => {
    const newValues = { ...initialValues, ...value };

    setValues(newValues);

    if (validate) {
      const result = validateForm(schema, newValues);

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...result.errors,
      }));
      setIsValid(!result.hasError);
    }
  };

  /**
   * The form field blur event. Runs validation for the field.
   * @param {string} name The name of the form field.
   * @param {boolean} validate Whether to run validation on the change. Default: `true`.
   */
  const onBlur = (name, validate = true) => {
    if (validate) {
      const result = _validate(values, _set({}, name, validate));
      onError(name, _get(result.errors, name));
      setIsValid(!result.hasError);
    }
  };

  /**
   * Validates all the form values.
   * @returns {boolean} Whether valid or not.
   */
  const validate = () => {
    const result = _validate(values);
    setErrors(result.errors);
    setIsValid(!result.hasError);
    return !result.hasError;
  };

  /**
   * Validates all the form values.
   * @returns {boolean} Whether valid or not.
   */
  const reset = () => {
    setValues(initialValues);
    setErrors({});
    setIsValid(false);
  };

  const refValidate = useRef();
  const refOnChange = useRef();
  const refOnBlur = useRef();
  const refOnError = useRef();

  const _debounce = (ref, fn, wait = DEFAULT_DEBOUNCE_WAIT) => {
    ref.current && clearTimeout(ref.current);
    ref.current = setTimeout(fn, wait);
  };

  /**
   * Validates all the form values after a set period of time.
   */
  const debouncedValidate = (wait) => {
    _debounce(refValidate, () => validate(), wait);
  };

  /**
   * The form field change event. Enacted after a set period of time.
   * @param {string} name The name of the form field.
   * @param {any} value The updated form field value.
   * @param {number} wait How long to wait before executing. Default: `DEFAULT_DEBOUNCE_WAIT`.
   */
  const onDebouncedChange = (name, value, wait) => {
    _debounce(refOnChange, () => onChange(name, value), wait);
  };

  /**
   * The form field blur event. Runs validation for the field after a set period of time.
   * @param {string} name The name of the form field.
   * @param {number} wait How long to wait before executing. Default: `DEFAULT_DEBOUNCE_WAIT`.
   */
  const onDebouncedBlur = (name, wait) => {
    _debounce(refOnBlur, () => onBlur(name), wait);
  };

  /**
   * Sets the form field's error after a set period of time.
   * @param {string} name The name of the form field.
   * @param {any} error The associated error.
   * @param {number} wait How long to wait before executing. Default: `DEFAULT_DEBOUNCE_WAIT`.
   */
  const onDebouncedError = (name, error, wait) => {
    _debounce(refOnError, () => onError(name, error), wait);
  };

  return {
    values,
    errors,
    isValid,
    validate,
    reset,
    onChange,
    onBlur,
    onError,
    onChangeAll,
    debouncedValidate,
    onDebouncedChange,
    onDebouncedBlur,
    onDebouncedError,
  };
}
