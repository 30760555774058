import React, { useState } from 'react';
import { ButtonGroup, RadioGroup } from '@sine/sine-ui-components';

import { ButtonSolid, CardContent, CardFooter, SubTitle, Title } from '../components';
import { ERROR_8, QUESTION_3 } from './pages';
import { VACCINE_NAME } from '../constants/vaccines';

const vaccineOptions = [
  {
    value: VACCINE_NAME.PFIZER,
    label: 'Pfizer COVID-19 vaccine',
  },
  {
    value: VACCINE_NAME.MODERNA,
    label: 'Moderna COVID-19 vaccine',
  },
  {
    value: VACCINE_NAME.UNKNOWN,
    label: 'I don’t know / Other',
  },
];

export default function VaccineRadioButton({ text, onNext, setState }) {
  const [vaccine, setVaccine] = useState('');

  const onRadioChange = (mfg) => {
    setVaccine(mfg);
    setState((oldState) => ({ ...oldState, mfg }));
  };

  const onNextClick = () => {
    if (vaccine.mfg === 'u') {
      onNext(undefined, ERROR_8.id);
    } else {
      onNext(undefined, QUESTION_3.id);
    }
  };

  return (
    <>
      <CardContent>
        <Title>COVID Vaccine Prescreen</Title>
        <SubTitle>Please answer the questions below</SubTitle>
        {text}
        <RadioGroup
          format='list'
          options={vaccineOptions}
          onChange={onRadioChange}
          value={vaccine}
          noSpaceReservation
        />
      </CardContent>

      <CardFooter>
        <ButtonGroup end>
          <ButtonSolid onClick={onNextClick} disabled={!vaccine}>
            Next
          </ButtonSolid>
        </ButtonGroup>
      </CardFooter>
    </>
  );
}
