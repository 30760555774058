import HapiJoi from '@hapi/joi';
import { extensions } from '@sine/joi-extensions';

const Joi = HapiJoi.extend(extensions.joiPhoneNumber);

export const MIN_CHARACTERS = 2;
export const MAX_CHARACTERS = 255;

export const VALIDATION_MESSAGE = {
  FIRST_NAME_REQUIRED: 'Your first name is required',
  FIRST_NAME_TOO_LONG: 'Your first name is too long',
  LAST_NAME_REQUIRED: 'Your last name is required',
  LAST_NAME_TOO_LONG: 'Your last name is too long',
  // EMAIL_REQUIRED: 'Your email address is required',
  // EMAIL_INVALID: 'Invalid email address',
  // MOBILE_REQUIRED: 'Your mobile number is required',
  // MOBILE_INVALID: 'Invalid mobile number',
  DOB_REQUIRED: 'Your date of birth is required',
  NO_WHITESPACE: 'Spaces are not allowed',
};

export const personalDetailsSchema = Joi.object()
  .keys({
    firstName: Joi.string().min(MIN_CHARACTERS).max(MAX_CHARACTERS).trim().strict().messages({
      'any.required': VALIDATION_MESSAGE.FIRST_NAME_REQUIRED,
      'string.empty': VALIDATION_MESSAGE.FIRST_NAME_REQUIRED,
      'string.min': VALIDATION_MESSAGE.FIRST_NAME_REQUIRED,
      'string.max': VALIDATION_MESSAGE.FIRST_NAME_TOO_LONG,
      'string.trim': VALIDATION_MESSAGE.NO_WHITESPACE,
    }),
    lastName: Joi.string().min(MIN_CHARACTERS).max(MAX_CHARACTERS).trim().strict().messages({
      'any.required': VALIDATION_MESSAGE.LAST_NAME_REQUIRED,
      'string.empty': VALIDATION_MESSAGE.LAST_NAME_REQUIRED,
      'string.min': VALIDATION_MESSAGE.LAST_NAME_REQUIRED,
      'string.max': VALIDATION_MESSAGE.LAST_NAME_TOO_LONG,
      'string.trim': VALIDATION_MESSAGE.NO_WHITESPACE,
    }),
    // email: Joi.string()
    //   .trim()
    //   .email({ minDomainSegments: 2, tlds: { allow: false } })
    //   .required()
    //   .messages({
    //     'any.required': VALIDATION_MESSAGE.EMAIL_REQUIRED,
    //     'string.empty': VALIDATION_MESSAGE.EMAIL_REQUIRED,
    //     'string.email': VALIDATION_MESSAGE.EMAIL_INVALID,
    //   }),
    // mobile: Joi.string().phoneNumber().required().messages({
    //   'string.phoneNumber': VALIDATION_MESSAGE.MOBILE_INVALID,
    //   'string.empty': VALIDATION_MESSAGE.MOBILE_REQUIRED,
    // }),
    fin: Joi.string().optional().allow(null, ''),
    csn: Joi.string().optional().allow(null, ''),
    dob: Joi.when('fin', {
      is: Joi.string().equal('', null),
      then: Joi.when('csn', {
        is: Joi.string().equal('', null),
        then: Joi.date().required(),
        otherwise: Joi.date().optional().allow(null, ''),
      }),
      otherwise: Joi.date().optional().allow(null, ''), 
    }).messages({
      'date.base': VALIDATION_MESSAGE.DOB_REQUIRED,
    }),
    mfg: Joi.string().optional().allow(null, ''),
    dose: Joi.string().optional().allow(null, ''),
  })
  .required();
