/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card } from '@sine/sine-ui-components';

import atriumHealthLogo from '../assets/images/atrium-health-logo.svg';
import backgroundImage from '../assets/images/background.svg';
import { PERSONAL_DETAILS, QUESTION_1, QUESTION_3, pages } from '../pages/pages';
import { ANSWER } from '../constants/answer';
import getQueryParams from '../helpers/getQueryParams';

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: fixed;
  top: 25%;
  left: 0%;
  bottom: 0;
  z-index: -1;
`;

const StyledCard = styled(Card)`
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border: none;
  min-height: 400px;
  margin-bottom: 10px;
`;

const LinkedLogo = styled(({ href, src, alt, className }) => (
  <div className={className}>
    <a href={href} target='_blank'>
      <img src={src} alt={alt} />
    </a>
  </div>
))`
  a {
    display: inline-block;
  }
`;

const AtriumHealth = styled(LinkedLogo)`
  margin-bottom: 16px;
  img {
    height: 40px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 8px;
    img {
      height: 28px;
    }
  }
`;

const Container = styled.div`
  margin: 16px auto;
  max-width: 400px;
`;

const StyledLink = styled.a`
  color: #ffffff;
  font-size: 14px;
  &:hover {
    opacity: 80%;
  }
`;

const StyledDiv = styled.div`
  margin: 16px 0;
  text-align: center;
`;

const PrivacyStatement = styled(StyledDiv)`
  color: #ffffff;
  opacity: 0.7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`;

export default function MainContainer() {
  const [pageId, setPageId] = useState(PERSONAL_DETAILS.id);
  const [goToId, setGoToId] = useState(QUESTION_1.id);
  const [state, setState] = useState({});

  const pageConfig = pages[pageId];
  const Page = pageConfig.Page;

  return (
    <>
      <Background />

      <Container>
        <AtriumHealth href='https://atriumhealth.org' src={atriumHealthLogo} alt='Atrium Health' />

        <StyledCard className='card-color'>
          <Page
            state={state}
            setState={setState}
            onNext={(event, altId = pageConfig.nextGoTo) => {
              altId && setGoToId(altId);
              setPageId(altId || goToId);
            }}
            onYes={() => {
              setGoToId(pageConfig.yesGoTo);
              setState((s) => ({ ...s, [pageConfig.id]: ANSWER.YES }));
            }}
            onNo={() => {
              setGoToId(pageConfig.noGoTo);
              setState((s) => ({ ...s, [pageConfig.id]: ANSWER.NO }));
            }}
            setGoToId={setGoToId}
          />
        </StyledCard>
        <StyledDiv>
          <StyledLink href='https://atriumhealth.org/for-patients-visitors/privacy/terms-of-use'>
            Atrium Health Terms and Conditions
          </StyledLink>
        </StyledDiv>
        <StyledDiv>
          <StyledLink href='https://atriumhealth.org/for-patients-visitors/online-privacy-policy'>
            Atrium Health Online Privacy Practices
          </StyledLink>
        </StyledDiv>
        <PrivacyStatement>
          Your personal information will be stored only on the browser of the device used to access this site. We do not
          collect, access, or otherwise process your personal information stored on the device. You can delete your
          personal information from the device by clearing the cache on the browser that accessed this site
        </PrivacyStatement>
      </Container>
    </>
  );
}
